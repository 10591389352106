
// import React, { Component } from 'react'
// import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
// // import ReactFullpage from '@fullpage/react-fullpage';
// import Banner from '../components/HomeBanner'
// import { Container, Row, Col } from 'reactstrap';
// import Transparency from '../images/About/Transparency.png'
// import Guaranteed from '../images/About/Guaranteed.png'
// import Empowerment from '../images/About/Empowerment.png'
// import { Fade } from 'react-slideshow-image';
// import 'react-slideshow-image/dist/styles.css'
// import AboutBanner1 from '../images/About/banner1.jpeg'
// import AboutBanner2 from '../images/About/banner2.jpeg'
// import AboutBanner3 from '../images/About/banner3.jpeg'
// import AboutBanner4 from '../images/About/banner4.jpeg'
// import AboutBanner5 from '../images/About/banner5.jpeg'
// import AboutBanner6 from '../images/About/banner6.jpeg'
// import Card1 from '../images/How-We-Do/img1.jpg'
// import Card2 from '../images/How-We-Do/img2.jpg'
// import Card3 from '../images/How-We-Do/img3.jpg'
// import Card4 from '../images/How-We-Do/img4.jpg'
// import Card5 from '../images/How-We-Do/img5.jpg'
// import Card6 from '../images/How-We-Do/img6.jpg'
// // import { BiBullseye } from 'react-icons/bi';
// import ExploreProducts from '../components/ExploreProducts'
// // import Header from '../components/Header'
// import Footer from '../components/Footer'
// // import { Lines } from 'react-preloaders';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// // import Loader from "react-loader-spinner";
// // import Logo from '../images/logo.png'
// import MainModal from '../components/ModalMain'
// import { FaArrowUp } from 'react-icons/fa';



// export default class Index extends Component {
//   render() {
//     return (
//       <Fullpage scrollingSpeed={1000}>

//         <FullPageSections>
//           <Container>
//             <Row>
//               <MainModal />
//             </Row>
//           </Container>
//           <div className="container arrowContainerP">
//                             <div className="arrowSection">
//                               <div className="arrowContainer slideDownDiv">
//                                 <span></span>
//                                 <span></span>
//                                 <span></span>
//                                 <span></span>
//                               </div>
//                             </div>
//                           </div>

//           <FullpageSection>
//             <Banner id="ArrowTop" />
//           </FullpageSection>
//           <FullpageSection>
//             <div className="about_section">
//               <div className="section_center">

//                 <Container>
//                   <Row>
//                     <Col md="6">
//                       <div>
//                         <h1>About Us</h1>
//                         <hr className="about_border" />
//                         <p>GREYSPACE, established in 2019 is a venture belongs to a family of artists and craftsmen where<strong> QUALITY IS OUR IDENTITY</strong> besides maintaing natural and sturdy design. We have emerged as a leading brand in the realm of furniture and interior decorative items as we design and manufacture furniture products that alignes with exact expectations of the market. Our motive is not only to manufacture furniture but also to craft the trust. Our skilled craftsmen transform the raw wood and metal into alive furniture that is witnessed by its feel which turns your space into home. We strive for design excellence in all its home furnishings, standards, and quality. Our aim is to continuously introduce new and exciting collections of industrial, contemporary, rustic, and modern products and designs. Our friendly team of professionals will help, inspire, educate, problem-solve and satisfy our customers’ needs and passions.
//                         GREYSPACE introduces new and unique collections of furniture that are sure to inspire and stand by you for years. Let the design team at GREYSPACE help you to create and deliver the dream piece to add spark to your space. Leave your order requirement<a className="click" href="/ContactUs"><strong> here.</strong></a><br />
//                           We look forward to working with you!
//                       </p>
//                         <Row>
//                           <Col md="4">
//                             <div className="about_card">
//                               <img src={Transparency} alt="img" />
//                               <h6>Transparency</h6>
//                             </div>
//                           </Col>
//                           <Col md="4">
//                             <div className="about_card">
//                               <img src={Guaranteed} alt="img" />
//                               <h6>Guaranteed</h6>
//                             </div>
//                           </Col>
//                           <Col md="4">
//                             <div className="about_card">
//                               <img src={Empowerment} alt="img" />
//                               <h6>Empowerment</h6>
//                             </div>
//                           </Col>
//                         </Row>
//                       </div>
//                     </Col>
//                     <Col md="6">
//                       <div className="slide-container">
//                         <Fade>
//                           <div className="each-slide">
//                             <div className="about_banner">
//                               <img src={AboutBanner1} alt="img" />
//                             </div>
//                           </div>
//                           <div className="each-slide">
//                             <div className="about_banner">
//                               <img src={AboutBanner2} alt="img" />
//                             </div>
//                           </div>
//                           <div className="each-slide">
//                             <div className="about_banner">
//                               <img src={AboutBanner3} alt="img" />
//                             </div>
//                           </div>
//                           <div className="each-slide">
//                             <div className="about_banner">
//                               <img src={AboutBanner4} alt="img" />
//                             </div>
//                           </div>
//                           <div className="each-slide">
//                             <div className="about_banner">
//                               <img src={AboutBanner5} alt="img" />
//                             </div>
//                           </div>
//                           <div className="each-slide">
//                             <div className="about_banner">
//                               <img src={AboutBanner6} alt="img" />
//                             </div>
//                           </div>
//                         </Fade>
//                       </div>
//                     </Col>
//                   </Row>
//                 </Container>
//               </div>
//             </div>
//           </FullpageSection>
//           <FullpageSection>
//             <div className="gray_section">
//               <div className="section_center1">
//                 <Container>
//                   <h1>This is How We Do!</h1>
//                   <hr className="gray_hr" />
//                   <Row>
//                     <Col md="4">
//                       <div className="work_card"> <img src={Card1} alt="" />
//                         <div className="work_contant ">
//                           <h4><a href="/">Procurement of Quality Raw Material</a></h4>
//                         </div>
//                         <div className="work_contant hover text_center work_main">
//                           <h4><a href="/">Procurement of Quality Raw Material </a></h4>
//                           <p>Purchase of the raw wood is done through legal channels which is seasoned, treated and air-dried naturally and by force to minimize any possibility of wrapping of wood worms. </p>
//                           <div className="work_file text_center">
//                           </div>
//                         </div>
//                       </div>
//                     </Col>
//                     <Col md="4">
//                       <div className="work_card"> <img src={Card2} alt="" />
//                         <div className="work_contant ">
//                           <h4><a href="/">Cutting and Shaping</a></h4>
//                         </div>
//                         <div className="work_contant hover text_center work_main">
//                           <h4><a href="/">Cutting and Shaping </a></h4>
//                           <p> Next to procurement, we move ahead to design the quality furniture by cutting and shaping as per the requirement of our clients. </p>
//                           <div className="work_file text_center">
//                           </div>
//                         </div>
//                       </div>
//                     </Col>
//                     <Col md="4">
//                       <div className="work_card"> <img src={Card3} alt="" />
//                         <div className="work_contant ">
//                           <h4><a href="/">Sanding and Finishing</a></h4>
//                         </div>
//                         <div className="work_contant hover text_center work_main">
//                           <h4><a href="/">Sanding and Finishing </a></h4>
//                           <p> Our craftsmen keenly looks into the process of sanding to avoid any sort of scratches, cuts and uneven surfaces and deliver impeccable quality product. The final finishing of the furniture is done with shades as per customers need.</p>
//                           <div className="work_file text_center">
//                           </div>
//                         </div>
//                       </div>
//                     </Col>
//                     <Col md="4">
//                       <div className="work_card"> <img src={Card4} alt="" />
//                         <div className="work_contant ">
//                           <h4><a href="/">Quality Check</a></h4>
//                         </div>
//                         <div className="work_contant hover text_center work_main">
//                           <h4><a href="/">Quality Check </a></h4>
//                           <p> Every piece of furniture is manufactured through a stringent multi-step quality check which helps us to ensure the accuracy of furniture to your expectations. </p>
//                           <div className="work_file text_center">
//                           </div>
//                         </div>
//                       </div>
//                     </Col>
//                     <Col md="4">
//                       <div className="work_card"> <img src={Card5} alt="" />
//                         <div className="work_contant ">
//                           <h4><a href="/">Labeling and Multi-layered Packing</a></h4>
//                         </div>
//                         <div className="work_contant hover text_center work_main">
//                           <h4><a href="/">Labeling and Multi-layered Packing </a></h4>
//                           <p>To deliver the furniture in perfect condition, we safe and secure the final product by multi layered protective packing with custom labeling on the demand of our valued clients.  </p>
//                           <div className="work_file text_center">
//                           </div>
//                         </div>
//                       </div>
//                     </Col>
//                     <Col md="4">
//                       <div className="work_card"> <img src={Card6} alt="" />
//                         <div className="work_contant ">
//                           <h4><a href="/">Worldwide Delivery</a></h4>
//                         </div>
//                         <div className="work_contant hover text_center work_main">
//                           <h4><a href="/">Worldwide Delivery </a></h4>
//                           <p>After the final packaging, our professional shipping team schedules and monitors the loading and shipping of the furniture to the last mile within the committed time frame. </p>
//                           <div className="work_file text_center">
//                           </div>
//                         </div>
//                       </div>
//                     </Col>
//                   </Row>
//                 </Container>
//               </div>
//             </div>
//           </FullpageSection>
//           <FullpageSection>
//             <div className="service_section">
//               <div className="section_center1">
//                 <Container>
//                   <Row>
//                     <Col md="6">
//                       <div className="who_are_card">
//                         <h1>Who Are We?</h1>
//                         <hr />
//                         <p>We are the manufacturer of product-mix furniture, chandeliers and decor items for</p>
//                         <ul>
//                           <li>Living Room</li>
//                           <li>Dining Room</li>
//                           <li>Hall Way</li>
//                           <li>Home - Office</li>
//                           <li>Lounge</li>
//                           <li>Bedroom</li>
//                         </ul>
//                         <p>Produced by a team of experienced craftsmen who has sense of years of creativity & designing and the entire process from scratch to end is done, which ensures that the final product will entice you!</p>
//                       </div>
//                     </Col>
//                     <Col md="6">
//                       <div className="why_us_card">
//                         <h1>Why Us?</h1>
//                         <hr />
//                         <p>One’s priority, before buying furniture, is the perfect use of space with suitability as per interior. And our designing team mainly focuses to craft that furniture, which is appealing, gorgeous, and attractive with the best use. The soul of our work resides in every product that will enhance the look of your space.</p>
//                         <p>We achieve this because we believe in providing quality not just the first time, but every time.</p>
//                         <ul>
//                           <li>Experience & knowledge</li>
//                           <li>Competitive prices</li>
//                           <li>Top quality products</li>
//                           <li>Transparent and ethical business practices</li>
//                           <li>Growth oriented philosophy</li>
//                           <li>Tailor-made products</li>
//                         </ul>
//                       </div>
//                     </Col>
//                   </Row>
//                 </Container>
//               </div>
//             </div>
//           </FullpageSection>
//           <FullpageSection>
//             <div className="explore_section">
//               <div className="section_center1">
//                 <Container>
//                   <Row>
//                     <Col md="6">
//                       <ExploreProducts />
//                     </Col>
//                     <Col md="6">
//                       <div className="explore_section_card">
//                         <div className="explore_section_text">
//                           <h1>Explore Products!</h1>
//                           <hr />
//                           <p>We are showcasing here our versatile range and reach of internationally designed products all over the world with our <strong> INDIAN CRAFTSMANSHIP.</strong></p>
//                           <p>Our product range includes Dining Tables, Coffee Tables, Side Tables, End Tables, Bar Stools, Kitchen Stools, Arm Chairs, Chest of Drawers and Drinks' Trolleys, Media Units, TV Stands, Sideboards, Drinks Cabinets, Shelving, Bookcases, Bedside Tables, Laptop Tables, Consoles, Desks, Dressing Tables and many more...</p>
//                           <button>EXPLORE NOW</button>

                          
//                         </div>
//                       </div>

//                     </Col>
//                   </Row>
//                 </Container>
//               </div>
//             </div>
//           </FullpageSection>
//           <FullpageSection>
//             <Footer />
//             <div className="arrow_icon">
//               <a href="#ArrowTop">
//                 <FaArrowUp color="#fff" size={30} />
//               </a>
//             </div>

//           </FullpageSection>

//         </FullPageSections>

//       </Fullpage>
//     )
//   }
// }


import React, { Component, Fragment } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import ReactDOM from "react-dom";

// import "./styles.css";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false
    };
  }
  handleToggle() {
    this.setState({
      toggle: !this.state.toggle
    });
    console.log(this.state.toggle);
  }
  
//   initFullpage(){
//     new fullPage('#fullpage', {
//           afterResponsive: function(isResponsive){
//               if (isResponsive == true) {
//                   fullpage_api.destroy('all');
//              }else{
//                    initFullpage();
//              }
//           }
//     });
// }
  render() {
    
    return (
      
      <ReactFullpage
        scrollBar
        licenseKey={"YOUR_KEY_HERE"}
        verticalCentered

        render={({ state }) => {
          return (
            <Fragment>
              <ReactFullpage.Wrapper>
                <div className="section section1 fp-auto-height-responsive">
                  <h3>Section 1</h3>
                </div>
                {/* <div className={this.state.toggle ? "blur section" : "section"}>
                  <h3 onClick={() => this.handleToggle()}>
                    Click me to change toggle
                  </h3>
                </div> */}
                <div className="section section1 fp-auto-height-responsive">
                  <h3>Section 3</h3>
                </div>
                <div className="section  fp-auto-height-responsive">
                  <h3>Section 4</h3>
                </div>
              </ReactFullpage.Wrapper>
            </Fragment>
          );
        }}
      />
    );
  }
}

// const rootElement = document.getElementById("root");
// ReactDOM.render(<App />, rootElement);

